html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline}
article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}
body{line-height:1}
ol,ul{list-style:none}
blockquote,q{quotes:none}
blockquote:before,blockquote:after,q:before,q:after{content:'';content:none}
table{border-collapse:collapse;border-spacing:0}

.App {
  font-family: 'Playfair Display', serif;
  text-align: center;
  margin-top: 80px;
}

header {
    width: 100%;
    margin: 0 auto;
    position: fixed;
    z-index: 999999;
    padding: 5px 0;
    background: #ffffff;
    box-shadow: 1px 1px 10px 1px #10101010;
    float: left;
    top: 0;
    left: 0;
    height: 80px;
}

.container {
    max-width: 1280px;
    margin: auto;
}

.logo {
  width: 40%;
  float: left;
}

.logo img {
  height: 80px;
  float: left;
}

.logo h3 {
  font-family: 'Sree Krushnadevaraya', serif;
  font-size: 42px;
  line-height: 42px;
  color: #000;
  font-weight: normal;
  margin: 10px 20px 0;
  float: left;
}

.menu {
  margin-top: 30px;
  width: 60%;
  float: right;
}

.menu ul {
  float: right;
}

.menu li {
  float: left;
  margin: 0 25px;
}

.menu li i {
    font-size: 12px;
    position: absolute;
    left: 5px;
    top: 15px;
}

.menu li a {
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    color: #000000;
    text-decoration: none;
    transition: .3s all ease;
    padding: 10px 25px;
    border-radius: 25px;
    line-height: 16px;
    position: relative;
}

.menu li a:hover, .menu li a:focus, .menu li a:active {
    color: #ed1b2f;
}

.menu li .join-link {
    background-color: #ed1b2f;
    color: #ffffff!important;
    padding: 10px 20px 10px 45px;
}

.menu li .join-link i {
    left: 25px;
}

.banner {
    position: relative;
    z-index: 9999;
    padding: 0;
    float: left;
    width: 100%;
    clear: both;
    background-color: #ed1b2f;
}

.banner .slide-info figure {
    width: 100%;
    float: left;
    position: static;
}

.banner .slide-info figure img {
    width: 50%;
    float: right;
    height: auto;
    max-width: 500px;
}

.banner .amaravathi-banner figure img {
    max-width: 800px;
    position: absolute;
    top: -14%;
}

.banner .slide-info figcaption {
    width: 50%;
    float: left;
}

.banner h2 {
    font-family: 'Anton', sans-serif;
    font-size: 62px;
    font-weight: 400;
    line-height: 62px;
    color: #ffffff;
    text-align: left;
    max-width: 500px;
    margin-top: 100px;
}

.banner h2 span {
    font-family: 'Sree Krushnadevaraya', serif;
    font-size: 82px;
    line-height: 52px;
    color: #ffffff;
    font-weight: 500;
}

.banner p {
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    margin: 30px auto 35px;
    text-align: left;
    color: #f0f0f0;
    line-height: 30px;
    transition: .3s all ease;
}

.banner p>span {
    float: left;
    font-weight: 600;
    margin-right: 5px;
    font-size: 28px;
}

.banner p .Typewriter {
    width: auto;
    float: left;
    font-size: 28px;
    font-weight: 600;
    margin-right: -5px;
}

.banner p a {
    background-color: #101010;
    text-decoration: none;
    padding: 12px 35px;
    border-radius: 25px;
    color: #ffffff;
    font-size: 18px;
    line-height: 20px;
    transition: .3s all ease;
}

.banner p a:hover, .banner p a:focus {
    background: #ffffff;
    color: #ed1b2f;
}

.banner .awssld__content {
    background-color: transparent!important;
    display: block!important;
}

.banner .slide {
    position: relative;
}

.banner .slide img {
    height: 800px;
    width: 100%;
}

.banner .slide .slide-info {
    position: absolute;
    width: auto;
    left: 0;
    right: 0;
    margin: auto;
    top: 13%;
}

.banner .awssld__container {
    padding-bottom: 750px!important;
}

.awssld__controls__arrow-left:before, .awssld__controls__arrow-right:before {
    background: #ffffff!important;
    height: 25px!important;
    width: 3px!important;
}

.awssld__controls__arrow-right:before, .awssld__controls__arrow-left:before {
    top: -15%!important;
}

.awssld__controls__arrow-left:after, .awssld__controls__arrow-right:after {
    height: 25px!important;
    width: 3px!important;
    background: #ffffff!important;
}

.awssld__bullets {
    bottom: 30px!important;
    z-index: 9999;
    background: #ffffff;
    max-width: 130px;
    margin: auto;
    left: 0;
    right: 0;
    padding: 10px 0;
    border-radius: 30px;
}

.awssld__bullets button {
    width: 10px!important;
    height: 10px!important;
    background: #333333!important;
}




.initiative {
    width: 100%;
    clear: both;
    float: left;
    padding: 100px 0;
    background: #101010;
}

.initiative h3 {
    font-family: 'Lato', sans-serif;
    font-size: 28px;
    margin: 30px auto 35px;
    text-align: center;
    color: #f0f0f0;
}

.initiative h2 {
    font-family: 'Anton', sans-serif;
    font-size: 52px;
    font-weight: 400;
    line-height: 62px;
    color: #ffffff;
    text-align: center;
    max-width: 780px;
    margin: auto;
    text-transform: uppercase;
}

.initiative h2 span {
    color: #ed1b2f;
}

.initiative p {
    font-family: 'Lato', sans-serif;
    font-size: 22px;
    margin: 30px auto 45px;
    text-align: center;
    color: #f0f0f0;
}

.initiative .Typewriter {
    display: inline-block;
}

.initiative img {
    width: 150px;
}



.whatsapp-group {
    width: 100%;
    clear: both;
    float: left;
    padding: 100px 0;
}

.whatsapp-group-info, .whatsapp-group-graphic {
    float: left;
    width: 50%;
}

.whatsapp-group h3 {
    font-family: 'Anton', sans-serif;
    font-size: 52px;
    font-weight: 400;
    line-height: 62px;
    color: #101010;
    text-align: left;
    max-width: 780px;
    margin: 250px 0 0 0;
    text-transform: uppercase;
}

.whatsapp-group h2 {
    font-family: 'Anton', sans-serif;
    font-size: 52px;
    font-weight: 400;
    line-height: 62px;
    color: #101010;
    text-align: left;
    max-width: 780px;
    margin: 10px 0 40px;
    text-transform: uppercase;
}

.whatsapp-group h2 span {
    color: #ed1b2f;
}

.whatsapp-group p {
    text-align: left;
}

.whatsapp-group p a {
    font-family: 'Lato', sans-serif;
    background-color: #101010;
    text-decoration: none;
    padding: 12px 35px;
    border-radius: 25px;
    color: #ffffff;
    font-size: 18px;
    line-height: 20px;
    transition: .3s all ease;
}

.whatsapp-group p a:hover, .whatsapp-group p a:focus {
    background: #ed1b2f;
    color: #ffffff;
}

.whatsapp-group-graphic {
    text-align: center;
}

.whatsapp-group-graphic img {
    width: 400px;
}




.janasena {
    background: url('./images/janasena-bg.jpg')no-repeat top right;
    background-size: cover;
    width: 100%;
    float: left;
    clear: both;
    padding: 100px 0;
}

.janasena h2 {
    font-family: 'Anton', sans-serif;
    font-size: 72px;
    font-weight: 400;
    line-height: 82px;
    color: #ffffff;
    text-align: left;
    max-width: 600px;
}

.janasena h2 span {
    font-family: 'Sree Krushnadevaraya', serif;
    font-size: 92px;
    color: #ffffff;
    font-weight: 900;
}

.janasena p {
    font-family: 'Lato', sans-serif;
    font-size: 22px;
    margin: 20px 0 35px;
    text-align: left;
    color: #f0f0f0;
    max-width: 600px;
    line-height: 28px;
}

.janasena p a {
    background-color: #101010;
    text-decoration: none;
    padding: 12px 35px;
    border-radius: 25px;
    color: #ffffff;
    font-size: 18px;
    line-height: 20px;
    transition: .3s all ease;
}

.janasena p a:hover, .janasena p a:focus {
    background: #ffffff;
    color: #ed1b2f;
}


.shop-slider {
    width: 100%;
    float: left;
    clear: both;
    padding: 100px 0;
}

.shop-slider h2 {
    font-family: 'Anton', sans-serif;
    font-size: 42px;
    font-weight: 400;
    line-height: 42px;
    color: #101010;
    text-align: left;
    max-width: 600px;
    text-transform: uppercase;
    margin-bottom: 30px;
}

.shop-slider h2 span {
    font-family: 'Sree Krushnadevaraya', serif;
    font-size: 92px;
    color: #101010;
    font-weight: 900;
}

.shop-slider p {
    font-family: 'Lato', sans-serif;
    font-size: 22px;
    margin: 20px 0 35px;
    text-align: left;
    color: #101010;
    max-width: 600px;
    line-height: 28px;
}

.shop-slider p a {
    background-color: #101010;
    text-decoration: none;
    padding: 12px 35px;
    border-radius: 25px;
    color: #ffffff;
    font-size: 18px;
    line-height: 20px;
}

.InfiniteCarouselArrowIcon {
    padding: 5px!important;
    border: solid #101010!important;
    border-width: 0 3px 3px 0!important;
}

.InfiniteCarouselArrowPrev {
    left: -25px!important;
}

.InfiniteCarouselArrowNext {
    right: -25px!important;
}

.InfiniteCarouselDots {
    bottom: -30px!important;
}

.InfiniteCarouselDotIcon {
    background-color: #101010!important;
    width: 12px!important;
    height: 12px!important;
}

.InfiniteCarouselDotActiveIcon {
    background-color: #ed1b2f!important;
    width: 15px!important;
    height: 15px!important;
}


.news {
    width: 100%;
    float: left;
    clear: both;
    padding: 50px 0 100px;
}

.news figure {
    width: 100%;
    float: left;
}

.news figure img {
    float: right;
    width: 50%;
}

.news h2 {
    font-family: 'Anton', sans-serif;
    font-size: 42px;
    font-weight: 400;
    line-height: 42px;
    color: #101010;
    text-align: left;
    max-width: 600px;
    margin-bottom: 30px;
}

.news .article {
    float: left;
    width: 100%;
    margin-bottom: 50px;
}

.news h3 {
    font-family: 'Anton', sans-serif;
    font-size: 28px;
    font-weight: 400;
    line-height: 38px;
    color: #ed1b2f;
    text-align: left;
    max-width: 500px;
    margin: 50px 0 5px 0;
}

.news h5 {
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    margin: 0;
    text-align: left;
    color: #999999;
    max-width: 550px;
    line-height: 26px;
}

.news p {
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    margin: 10px 0 35px;
    text-align: left;
    color: #666666;
    max-width: 550px;
    line-height: 26px;
}

.news p a {
    background-color: #101010;
    text-decoration: none;
    padding: 12px 35px;
    border-radius: 25px;
    color: #ffffff;
    font-size: 18px;
    line-height: 20px;
    transition: .3s all ease;
}

.news p a:hover, .news p a:focus {
    background: #ed1b2f;
    color: #ffffff;
}


.members {
    width: 100%;
    float: left;
    clear: both;
}

.members img {
    width: 150px;
}


.shop {
    float: left;
    width: 100%;
    clear: both;
    margin: 70px 0;
}

.shop h2 {
    font-family: 'Anton', sans-serif;
    font-size: 42px;
    font-weight: 400;
    line-height: 42px;
    color: #101010;
    text-align: left;
    max-width: 600px;
    margin-bottom: 30px;
}

.shop .product {
    width: 50%;
    float: left;
    position: relative;
    overflow: hidden;
    transition: .2s all ease;
}

.shop .product:hover img, .shop .product:focus img {
    cursor: pointer;
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
}

.shop .product img {
    width: 100%;
    float: left;
    transition: .2s all ease;
}


.reach-us h2 {
    color: #ffffff;
    font-size: 42px;
    line-height: 52px;
    max-width: 850px;
}

.reach-us h2 span, .reach-us h2 a {
    color: #ed1b2f;
}

.mobile-menu {
    float: right;
    width: 180px;
    display: none;
    margin-top: 20px;
    position: absolute;
    right: 25px;
}

.navBtn {
    float: right;
    text-align: center;
    width: 50px;
    border: 1px solid #999999;
    height: auto;
    border-radius: 5px;
    padding: 8px 0;
    transition: .5s all ease;
}

.navBtn:hover {
    background: #f0f0f0;
    cursor: pointer;
}

.navBtn span {
    width: 60%;
    height: 2px;
    background: #999999;
    float: left;
    margin: 3px 20%;
}

.mobile-menu:hover ul {
    display: block;
    cursor: pointer;
}

.mobile-menu ul {
    float: left;
    width: 100%;
    padding: 0;
    margin: 15px 0 0 0;
    background: #ffffff;
    border: 1px solid #ffffff;
    display: none;
}

.mobile-menu li {
    text-align: left;
    padding: 12px 30px;
    border-bottom: 1px solid #ec4b5a;
    background: #ed1b2f;
}

.mobile-menu li a {
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    color: #ffffff;
    text-decoration: none;
    transition: .3s all ease;
    padding: 10px 0;
    border-radius: 25px;
    line-height: 16px;
    position: relative;
    text-align: left;
}

footer {
    float: left;
    width: 100%;
    clear: both;
    margin: 0;
    padding: 70px 0;
    background: #101010;
}

footer .logo h3 {color: #ffffff;}

footer .social {
    float: right;
    width: auto;
}

footer .social li {
    float: left;
    margin: 30px 15px;
    font-size: 22px;
}

footer .social li a {
    color: #ffffff;
    transition: .3s all ease;
}

footer .logo h3:hover, footer .social li a:hover {
    color: #ed1b2f;
}


@media all and (max-width:1600px) {
    .banner .awssld__container {
        padding-bottom: 650px!important;
    }

    .banner .slide .slide-info {
        top: 8%;
    }

    .banner .slide-info figure {
        width: 90%;
        margin: 0 5%;
    }

    .container {
        width: 90%;
    }

    .whatsapp-group-graphic img {
        width: 330px;
    }

    .janasena {
        background-position: top center;
    }

    .whatsapp-group h3 {
        margin: 25% 0 0 0;
    }
}

@media all and (max-width:1300px) {
    .menu li {
        margin: 0;
    }

    .menu li a {
        font-size: 16px;
        padding: 10px 30px;
    }

    .banner .awssld__container {
        padding-bottom: 550px!important;
    }

    .banner .slide .slide-info {
        top: 5%;
    }

    .banner .amaravathi-banner figure img {
        top: -5%;
    }

    .banner .slide-info figure img {
        width: 45%;
    }

    .banner h2 {
        font-size: 52px;
        line-height: 52px;
    }

    .banner h2 span {
        font-size: 62px;
        line-height: 42px;
    }

    .banner p {
        font-size: 18px;
        line-height: 26px;
    }

    .banner p .Typewriter, .banner p>span {
        font-size: 22px;
    }

    .initiative h2 {
        font-size: 42px;
        line-height: 52px;
        max-width: 680px;
        margin: 0 auto;
        width: 90%;
    }

    .whatsapp-group h2, .whatsapp-group h3, .janasena h2 {
        font-size: 42px;
        line-height: 52px;
    }

    .whatsapp-group-graphic img {
        width: 300px;
    }

    .news .article figcaption {
        width: 50%;
    }

    .news figure img {
        float: right;
        width: 45%;
    }

    .news h3 {
        margin: 0 0 15px 0;
    }

    .initiative {
        padding: 50px 0 100px;
    }
}


@media all and (max-width:1000px) {

    .logo, .menu, .banner .slide-info figcaption, .banner .slide-info figure img, .whatsapp-group-info, .whatsapp-group-graphic, .news figure img, .news .article figcaption, footer .logo, footer .social, .shop .product {
        width: 100%;
        float: left;
        margin: 10px auto;
    }

    .banner .awssld__container {
        padding-bottom: 600px!important;
    }

    .logo {
        width: 250px;
    }

    .logo h3 {
        font-size: 32px;
        line-height: 42px;
        margin: 10px 10px 0;
    }

    .menu ul {
        float: none;
        width: 330px;
        margin: auto;
    }

    .menu li {
        float: left;
        margin: 20px 10px 0;
    }

    .menu li a {
        font-size: 14px;
    }

    .joinmain {
        padding: 75px 0 300px;
    }

    .joinmain h2 {
        font-size: 42px;
        line-height: 42px;
        width: 90%;
        margin: auto;
    }

    .joinmain h2 span {
        font-size: 62px;
        color: #ed1b2f;
    }

    .joinmain p {
        font-size: 16px;
        margin: 20px auto 40px;
        width: 70%;
        line-height: 20px;
    }

}

@media all and (max-width:900px) {
    .menu, .InfiniteCarouselDots {
        display: none;
    }

    .mobile-menu {
        display: block;
    }

    .logo img {
        height: 60px;
    }

    .logo h3 {
        line-height: 32px;
        margin: 8px 10px 0;
    }

    .banner .awssld__container {
        padding-bottom: 800px!important;
    }

    .banner .slide-info figure img {
        max-width: 300px;
        float: none;
    }

    .banner h2 {
        font-size: 42px;
        line-height: 42px;
        margin: 0 auto;
        text-align: center;
    }

    .banner h2 span {
        font-size: 52px;
        line-height: 32px;
    }

    .banner p {
        text-align: center;
        margin: 20px auto 50px;
        max-width: 400px;
    }

    .banner .amaravathi-banner figure img {
        position: static;
        top: 0;
    }

    .whatsapp-group {
        padding: 50px 0;
    }

    .whatsapp-group h2, .whatsapp-group h3, .janasena h2 {
        font-size: 32px;
        line-height: 42px;
        margin: 0 0 20px 0;
    }

    .whatsapp-group-graphic img {
        width: 100%;
        margin-top: 50px;
    }

    .shop-slider {
        width: 90%;
        margin: 0 5%;
    }

    .janasena {
        background-position: top left;
        padding: 80px 0 50px;
    }

    .news {
        padding: 50px 0 0 0;
    }

    .initiative h2 {
        font-size: 26px;
        line-height: 36px;
        max-width: 680px;
        margin: 0 auto;
        width: 90%;
        font-weight: 300;
    }
}